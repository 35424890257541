/*
 * @Author: Onein
 * @Date: 2020-04-15 10:38:16
 * @Last Modified by: Onein
 * @Last Modified time: 2020-05-03 17:22:58
 */
import { Resource, Fetch } from '@ekuaibao/fetch';
import { QuerySelect } from 'ekbc-query-builder';
import { app as api } from '@ekuaibao/whispered';
var creditModel = new Resource('/api/credit/v2/model');
var creditData = new Resource('/api/credit/v2/stafflog');
var orderMap = {
    ascend: 'ASC',
    descend: 'DESC'
};
export function fetchCreditModel(params) {
    if (params === void 0) { params = {}; }
    return creditModel.GET('', params);
}
export function createCreditModel(params) {
    if (params === void 0) { params = {}; }
    return creditModel.POST('', params);
}
export function editCreditModel(params) {
    if (params === void 0) { params = {}; }
    return creditModel.PUT('/$id', params);
}
export function searchCreditData(params) {
    if (params === void 0) { params = {}; }
    var sorter = params.sorter || {};
    var query = new QuerySelect()
        .filterBy(params.filter)
        .limit(params.start, params.count)
        .select("staffId(id,name,code,defaultDepartment(...)),...");
    if (sorter.field) {
        query.orderBy(sorter.field, orderMap[sorter.order]);
    }
    return creditData.POST('/point/list', query.value());
}
export function doExportCreditData(params) {
    if (params === void 0) { params = {}; }
    var ekbCorpId = encodeURIComponent(Fetch.ekbCorpId);
    var exportUrl = Fetch.fixOrigin(location.origin) + "/api/credit/v2/stafflog/point/export?corpId=" + ekbCorpId;
    api.emit('@vendor:download', exportUrl);
}
export function searchCreditDetailData(params) {
    if (params === void 0) { params = {}; }
    var sorter = params.sorter || {};
    var query = new QuerySelect()
        .filterBy(params.filter)
        .limit(params.start, params.count)
        .select("staffId(id,name,code,defaultDepartment(...)),...");
    if (sorter.field) {
        query.orderBy(sorter.field, orderMap[sorter.order]);
    }
    return creditData.POST('/point/detail/list', query.value());
}
export function editCreditPoint(params) {
    return creditData.PUT('/modify/point', params);
}
