/*
 * @Author: Onein
 * @Date: 2020-04-09 21:28:57
 * @Last Modified by: Onein
 * @Last Modified time: 2020-04-21 15:04:28
 */
import { fetchCreditModel } from './util/fetchUtil';
export default [
    {
        id: '@credit',
        'get:credit:model': function (params) { return fetchCreditModel(params); }
    },
    {
        path: '/credit-data',
        ref: '/',
        onload: function () { return import('./credit-data/credit-data-wrapper'); }
    },
    {
        path: '/credit-model',
        ref: '/',
        onload: function () { return import('./credit-model/credit-model-page'); }
    },
    {
        path: '/management-rules',
        ref: '/',
        onload: function () { return import('./management-rules/management-rules'); }
    },
    {
        point: '@@layers',
        prefix: '@credit',
        onload: function () { return import('./layers'); }
    }
];
